/* Countdown Timer Container */
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #0d1fa2, #293562);
  padding: 20px;
  border-radius: 12px;
  color: white;
  flex-wrap: nowrap; /* Prevent wrapping, keep elements in a row */
  gap: 10px; /* Add spacing between the items */
}

/* Individual Time Boxes */
.time-box {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  flex: 1 0 60px; /* Allow the boxes to shrink, with a minimum size of 60px */
  min-width: 60px; /* Prevents boxes from becoming too small */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

/* Time Value */
.time-box span {
  font-size: 3rem;
  font-weight: bold;
  display: block;
}

/* Label under each time box */
.time-box label {
  font-size: 1rem;
  margin-top: 5px;
  text-transform: uppercase;
  color: #8aa9d1;
}

/* Colon separator */
.colon {
  font-size: 3rem;
  margin: 0 5px;
  color: white;
}

/* Responsive adjustments for mobile */
@media screen and (max-width: 768px) {
  .countdown {
    padding: 15px;
    gap: 8px; /* Reduce the gap between time boxes */
  }

  .time-box {
    flex: 1 0 50px; /* Allow the boxes to shrink even more */
    min-width: 50px; /* Prevents the boxes from getting too small */
  }

  .time-box span {
    font-size: 2.5rem; /* Scale down the font size on mobile */
  }

  .time-box label {
    font-size: 0.9rem; /* Adjust the label font size for mobile */
  }

  .colon {
    font-size: 2.5rem; /* Scale down the colon size */
  }
}

@media screen and (max-width: 480px) {
  .countdown {
    padding: 10px;
  }

  .time-box {
    flex: 1 0 45px; /* Further reduce box size on very small screens */
    min-width: 45px;
  }

  .time-box span {
    font-size: 2rem; /* Further reduce font size */
  }

  .time-box label {
    font-size: 0.8rem; /* Reduce label font size */
  }

  .colon {
    font-size: 2rem; /* Scale down colon font */
  }
}
