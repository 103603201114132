body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, #0a2680, #0a0f29);
  color: #fff;
  height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.logo-center.absolute-top {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.content-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin-top: 120px;
}

.text-section {
  flex: 1;
  padding-right: 40px;
  text-align: left;
}

.countdown-section {
  flex: 1;
  padding-left: 40px;
}

.construction-tag {
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 10px;
  color: #8aa9d1;
  margin-top: 100px;
}

.coming-soon {
  font-size: 2rem;
  margin-top: 50px;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.2;
  
}

.description {
  font-size: 1rem;
  line-height: 1.5;
}

.email-form {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.email-form input {
  padding: 10px 0;
  font-size: 1rem;
  border: none;
  border-bottom: 2px solid #007bff;
  background: transparent;
  color: white;
  outline: none;
  flex: 1;
}

.email-form input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.email-form button {
  padding: 10px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, #00aaff, #0056b3);
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.email-form button:hover {
  background: linear-gradient(to right, #0056b3, #00aaff);
}

.social-links {
  margin-top: 15px;
}

.social-links a {
  margin-right: 15px;
  color: white;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}

footer {
  margin-top: 20px;
  font-size: 0.85rem;
}

.bottom-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  gap: 15px;
}

.social-links a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #0a0f29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.8rem;
  transition: 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.social-links a:hover {
  box-shadow: 0 0 15px 4px rgba(0, 150, 255, 0.8);
  color: rgba(0, 150, 255, 1);
  transform: scale(1.1);
}

/* Navbar */
.navbar {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: linear-gradient(to bottom, #0a2680, #0a0f29);
  padding: 10px 0;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.navbar ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin: 0 20px;
}

.navbar a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}

.navbar a:hover {
  color: #00aaff;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 200;
}

.logo-center.absolute-top {
  position: relative;
  margin: 0;
}

.auth-links {
  display: flex;
  gap: 15px;
}

.auth-link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  padding: 5px 15px;
  transition: all 0.3s ease;
}

.auth-link:hover {
  color: white;
}

.signup-link {
  border: 2px solid white;
  border-radius: 4px;
  padding: 5px 15px;
  background: linear-gradient(to right, #00aaff, #0056b3);
}

.signup-link:hover {
  background: linear-gradient(to right, #0056b3, #00aaff);
}

/* Side panel styling */
.side-panel {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden off-screen */
  width: 250px;
  height: 100%;
  background-color: rgba(5, 10, 30, 0.9);
  padding-top: 50px; /* Adds space from the top */
  z-index: 200;
  transition: left 0.3s ease-in-out;
  display: none; /* Hide side panel by default */
}

.side-panel.open {
  display: block; /* Show side panel when it's open */
  left: 0; /* Slide in from left */
}

/* Body content shift when side panel is open */
.body-shift {
  margin-top: 0; /* No shift when the panel is closed */
  transition: margin-top 0.3s ease-in-out;
}

.body-shift.open {
  margin-top: 100px; /* Apply shift only when side panel is open */
  transition: margin-top 0.3s ease-in-out;
}

/* Ensure hamburger menu is hidden by default on desktop */
.hamburger-menu {
  display: none; /* Hide hamburger menu by default */
}

.coming-soon-page {
  text-align: center;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

/* Responsive Design - Mobile */
@media screen and (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    gap: 15px;
  }

  .navbar a {
    font-size: 1rem;
    padding: 8px 15px;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-section,
  .countdown-section {
    padding: 0;
  }

  .email-form button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }

  .social-links a {
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }

  .coming-soon-page {
    padding: 30px 10px;
    height: auto;
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu on mobile */
    font-size: 30px;
    cursor: pointer;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
  }
  

  /* Navbar hidden on mobile */
  .navbar {
    display: none; /* Hide navbar on mobile */
  }

  .side-panel ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Stack links vertically */
  }

  .side-panel li {
    margin: 10px 0;
  }

  .side-panel a {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
  }

  .side-panel a:hover {
    color: #00aaff;
  }
}

