/* Social Links Container */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Individual Social Links */
.social-links a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #051562;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Icon color */
  font-size: 1.8rem; /* Icon size */
  transition: 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Hover Effect */
.social-links a:hover {
  box-shadow: 0 0 15px 4px rgba(0, 150, 255, 0.8); /* Glowing effect */
  color: rgba(0, 150, 255, 1); /* Icon glow effect */
  transform: scale(1.1); /* Slight zoom on hover */
}

/* Prevent icon from blocking link click */
.social-links i {
  pointer-events: none;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .social-links {
    gap: 10px; /* Reduce space between icons */
  }

  .social-links a {
    width: 45px; /* Smaller icon size on mobile */
    height: 45px;
    font-size: 1.6rem; /* Reduce icon size */
  }
}

@media screen and (max-width: 480px) {
  .social-links {
    gap: 8px; /* Reduce gap further on very small screens */
  }

  .social-links a {
    width: 40px; /* Further reduce size */
    height: 40px;
    font-size: 1.4rem; /* Further reduce icon size */
  }
}
